html, body {
  color: #fff;
  height: 100%;
  background-color: #000;
  font-family: Verdana, Arial, sans-serif;
  line-height: 1.5em;
  overflow: hidden;
}

.first {
  width: 100%;
  height: 33%;
  background-color: #000;
}

.second {
  width: 100%;
  height: 33%;
  background-color: red;
}

.third {
  width: 100%;
  height: 33%;
  background-color: #000;
}

.box {
  width: 155px;
  height: 300px;
  opacity: .5;
  background-color: #399;
  position: absolute;
  top: 0;
  left: 0;
}

.column {
  height: 100%;
  -ms-animation: lr 3s alternate infinite;
  animation: 3s infinite alternate lr;
  position: relative;
  left: 0;
}

.column_second {
  height: 100%;
  -ms-animation: lr_second 5s alternate infinite;
  animation: 5s infinite alternate lr_second;
  position: relative;
  left: 0;
}

.column_third {
  height: 100%;
  -ms-animation: lr_third 10s alternate infinite;
  animation: 10s infinite alternate lr_third;
  position: relative;
  left: 0;
}

.ball, .nyan_ball {
  width: 10px;
  height: 10px;
  -o-border-radius: 100%;
  -ms-border-radius: 100%;
  -ms-animation: ud 2.3s alternate infinite;
  background: red;
  border-radius: 100%;
  animation: 2.3s infinite alternate ud;
  position: relative;
  bottom: 0;
  left: 0;
}

.nyan {
  width: 70px;
  height: 100px;
  background: url("nyan-cat-vert_small.afe6e5a3.gif") no-repeat;
}

.nyan_type_vertical {
  width: 280px;
  height: 400px;
  opacity: 1;
  -o-transition: opacity 5s;
  background: url("nyan-cat-vert.d0e1a046.gif") no-repeat;
  transition: opacity 5s;
  display: none;
}

.nyan_type_vertical:hover {
  opacity: 0;
  -o-transition: opacity 1s;
  transition: opacity 1s;
}

.wrapper {
  width: 100%;
  height: 100%;
  padding: 0 280px 400px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.innerWrapper {
  width: 100%;
  height: 100%;
}

.shareWrapper {
  width: 20px;
  height: 32px;
  cursor: pointer;
  -webkit-transition-property: width height;
  -moz-transition-property: width height;
  -o-transition-property: width height;
  -ms-transition-property: width height;
  transition-property: width height;
  -o-transition-duration: 1s;
  -o-transition-timing-function: ease-in;
  transition-duration: 1s;
  transition-timing-function: ease-in;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.shareWrapper:hover {
  width: 250px;
  height: 32px;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-property: width height;
  -moz-transition-property: width height;
  -o-transition-property: width height;
  -ms-transition-property: width height;
  transition-property: width height;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.shareWrapper:hover .shareWrapperInner {
  width: 250px;
  height: 30px;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  -webkit-transition-property: width height border-radius border-left padding;
  -moz-transition-property: width height border-radius border-left padding;
  -o-transition-property: width height border-radius border-left padding;
  -ms-transition-property: width height border-radius border-left padding;
  transition-property: width height border-radius border-left padding;
  -o-transition-duration: 1s;
  border-left: 10px solid #000;
  border-radius: 0;
  padding-left: 5px;
  transition-duration: 1s;
}

.shareWrapperInner {
  width: 20px;
  height: 30px;
  -o-border-radius: 0px 0px 0px 30px;
  -ms-border-radius: 0px 0px 0px 30px;
  -webkit-transition-property: width height border-radius border-left padding;
  -moz-transition-property: width height border-radius border-left padding;
  -o-transition-property: width height border-radius border-left padding;
  -ms-transition-property: width height border-radius border-left padding;
  transition-property: width height border-radius border-left padding;
  -o-transition-duration: 1s;
  -o-transition-timing-function: ease-in;
  background: #fff;
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  border-radius: 0 0 0 30px;
  padding-left: 20px;
  transition-duration: 1s;
  transition-timing-function: ease-in;
  overflow: hidden;
}

body.no-animation .nyan_ball, body.no-animation .column, body.no-animation .column_second, body.no-animation .column_third {
  -ms-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes lr {
  0% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    left: 0;
  }

  50% {
    -ms-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    left: 100%;
  }

  100% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes lr_second {
  0% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    left: 0;
  }

  50% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    left: 100%;
  }
}

@keyframes lr_third {
  0% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    left: 0;
  }

  50% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    left: 100%;
  }
}

@keyframes ud {
  0% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    top: 0;
    transform: rotate(180deg);
  }

  50% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  100% {
    -ms-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    top: 100%;
  }
}

/*# sourceMappingURL=index.6ddf4516.css.map */
