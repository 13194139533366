@import 'common.styl'

html, body
    background-color #000
    color #fff
    font-family Verdana,Arial,sans-serif
    line-height 1.5em
    height 100%
    overflow hidden
stripe(color)
    width 100%
    height 33%
    background-color color
.first
    stripe(#000)
.second
    stripe(#ff0000)
.third
    stripe(#000)
/*============================================*/
.box
    position absolute
    left 0
    top 0
    width 155px
    height 300px
    opacity 0.5
    background-color #339999

column(animation)
    height 100%
    position relative
    left 0
    animation(animation)

.column
    column(lr 3s alternate infinite)
.column_second
    column(lr_second 5s alternate infinite)
.column_third
    column(lr_third 10s alternate infinite)

.ball
.nyan_ball
    position relative
    left 0
    bottom 0
    width 10px
    height 10px
    border-radius 100%
    background red
    animation ud 2.3s alternate infinite
/* =================================== */
.nyan
    width 70px
    height 100px
    background url("../i/nyan-cat-vert_small.gif") 0 0 no-repeat transparent
.nyan_type_vertical
    width 280px
    height 400px
    background url("../i/nyan-cat-vert.gif") 0 0 no-repeat transparent
    display none
    opacity 1
    transition opacity 5s

    &:hover
        opacity 0
        transition opacity 1s

.wrapper
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    padding 0 280px 400px 0
.innerWrapper
    width 100%
    height 100%
/* ================================= */
.shareWrapper
    position absolute
    top 0
    right 0
    width 20px
    height 32px
    overflow hidden
    cursor pointer

    transition-property width height
    transition-duration 1s
    transition-timing-function ease-in

    &:hover
        width 250px
        height 32px

        transition-timing-function ease-out
        transition-property width height
        transition-duration 1s
        &  .shareWrapperInner
            width 250px
            height 30px
            border-radius 0
            padding-left 5px
            border-left 10px solid #000

            transition-timing-function ease-out
            transition-property width height border-radius border-left padding
            transition-duration 1s
.shareWrapperInner
    width 20px
    height 30px
    border-left 1px solid #000
    border-bottom 1px solid #000
    border-radius 0px 0px 0px 30px
    background #ffffff
    overflow hidden
    padding-left 20px

    transition-property width height border-radius border-left padding
    transition-duration 1s
    transition-timing-function ease-in
/*============================================*/
@keyframes lr
    0%
        left 0
        animation-timing-function ease-in
    50%
        animation-timing-function ease-out
        left 100%
        /*transform matrix(1, 1, 0, -1, 1, 1);*/
    100%
        animation-timing-function ease-in
@keyframes lr_second
    0%
        left 0
        animation-timing-function ease-in
    50%
        animation-timing-function ease-in
    100%
        animation-timing-function ease-in
        left 100%
        /*transform matrix(-1, 0, 0, 1, 0, 0)*/
@keyframes lr_third
    0%
        left 0
        animation-timing-function ease-in
    50%
        animation-timing-function ease-in
    100%
        animation-timing-function ease-in
        left 100%
        /*transform matrix(-1, 1, 0, 1, 0, 0)*/
@keyframes ud
    0%
        top 0
        transform rotate(180deg)
        animation-timing-function ease-in
    50%
        animation-timing-function ease-in
    100%
        animation-timing-function ease-in
        top 100%

body.no-animation
    .nyan_ball
    .column
    .column_second
    .column_third
        pause()